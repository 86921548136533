// Route.js

// Define your route names and paths
const Routes = {
    HOME: '/',
    CONTACT: '/contact',
    SERVICES: '/services',
    PROJECTS: '/projects',


    // Add other routes as needed
};

// Export your routes object
export default Routes;